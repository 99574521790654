<script lang="ts" setup>
import WidgetTrialExpiration from '@/components/widgets/WidgetTrialExpiration.vue'
import Logo from '@/components/Logo.vue'
import LegacyBusinessNav from '@/components/BusinessNav.vue'
import BusinessNav from '@/components/navigation/BusinessNavigation.vue'
import BottomNavigation from '@/components/navigation/BottomNavigation.vue'
import BannerPaymentFailed from '@/components/banners/BannerPaymentFailed.vue'

const businessStore = useBusinessStore()
const legacyBusinessStore = useLegacyBusinessStore()

const hasPaymentIssues = computed(() => businessStore.hasPaymentIssues)

const { smAndDown, mdAndDown } = useDisplay()

const showNav = ref(true)

const route = useRoute()

const layoutMeta = computed(() => {
  return {
    sidebar: true,
    subNavType: null,
    ...route.meta.admin,
  }
})

const subNavType = computed(() => layoutMeta.value.subNavType)
const { navigationItems } = useSubNav(subNavType)

const isLoading = ref(false)

const getStripePaymentMethods = async () => {
  isLoading.value = true
  await legacyBusinessStore.getStripePaymentMethods()
  await businessStore.getBusiness()
  isLoading.value = false
}

onBeforeMount(() => {
  if (businessStore.isPrePricing2023 && businessStore.isTrialing) {
    void getStripePaymentMethods()
  }
})

const isTrialWidget = computed(() => {
  if (!layoutMeta.value.sidebar) return false

  return businessStore.isTrialing && !isLoading.value && !legacyBusinessStore.defaultPaymentMethod
})
</script>

<template>
  <v-main>
    <banner-payment-failed v-if="hasPaymentIssues" />
    <div v-if="smAndDown" class="px-4 pt-4 d-flex align-center">
      <v-app-bar-nav-icon :ripple="false" class="admin-layout__trigger" @click.stop="showNav = !showNav" />
      <nuxt-link :to="{ name: 'business-profile' }" class="text-no-decoration flex d-flex justify-center flex-grow-1">
        <logo data-cy="indexLogo" business="dark" />
      </nuxt-link>
      <div class="pl-9" />
    </div>
    <legacy-business-nav
      v-if="businessStore.isPrePricing2023"
      v-model="showNav"
      :is-locked="businessStore.isTrialExpired || businessStore.isLocked"
    />
    <business-nav v-else v-model="showNav" :is-locked="businessStore.isTrialExpired || businessStore.isLocked" />
    <v-container v-if="isLoading" class="fill-height">
      <v-row>
        <v-col class="d-flex justify-center">
          <v-progress-circular :size="70" color="text" indeterminate />
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else fluid class="content-container">
      <v-row>
        <v-col cols="12" lg="9" order="3" order-lg="2" class="content">
          <slot />
        </v-col>
        <v-col v-if="layoutMeta.sidebar" cols="12" lg="3" order="1" order-lg="3">
          <template v-if="isTrialWidget && businessStore.trialEndDate">
            <widget-trial-expiration :full-width="mdAndDown" :trial-end-date="businessStore.trialEndDate" />
            <hr v-if="mdAndDown" class="divider" />
          </template>
        </v-col>
      </v-row>
    </v-container>
    <bottom-navigation v-if="smAndDown" :items="navigationItems" />
  </v-main>
</template>

<style lang="sass" scoped>
.content-container
  padding: 6 * $bw
  +miw(md)
    padding-top: 24 * $bw

.content
  max-width: calc(#{$site-width-narrow} + #{($base-width * 3 * 2)})
  margin: 0 auto
  flex-grow: 1
  &--wide
    max-width: calc(#{$site-width} + #{($base-width * 3 * 2)})

.divider
  border: 1px solid $c-grey-light
  margin: #{$base-width * 6} -#{$base-width * 6}

[class*='col-']:empty
  display: none

.admin-layout__trigger
  &:before
    left: 50%
    top: 50%
    transform: translate(-50%,-50%)
    width: 46px
    height: 46px
    border-radius: 50%
    background: $c-primary
  &.v-btn--icon.v-size--default
    border-radius: 0
  span
    i
      height: 26px !important
      font-size: 34px !important
      width: 26px !important
  &:hover
    span
      color: $c-primary
</style>
