<script lang="ts">
import Logo from '@/components/Logo.vue'
import BusinessHelpMenu from '@/components/nav-menu/BusinessHelpMenu.vue'
import BusinessProfileMenu from '@/components/nav-menu/BusinessProfileMenu.vue'

export default defineComponent({
  components: {
    Logo,
    BusinessHelpMenu,
    BusinessProfileMenu,
  },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const businessStore = useBusinessStore()
    void businessStore.getBusiness()
    void businessStore.getSeals()
    const userPlanInfo = useUserPlanInfo()
    const companyHasManualInvoicingEnabled = computed(() => businessStore.isManuallyInvoiced)
    const hideBillingNav = computed(() => {
      return companyHasManualInvoicingEnabled.value || userPlanInfo.isLegacyPlan.value
    })
    const seals = computed(() => businessStore.seals)
    const bizName = computed(() => businessStore.name)
    return { companyHasManualInvoicingEnabled, bizName, seals, hideBillingNav }
  },
  computed: {
    isMobile() {
      return this.$vuetify.display.smAndDown
    },
    menuState: {
      get() {
        return this.modelValue
      },
      set(newValue: boolean) {
        this.$emit('update:modelValue', newValue)
      },
    },
  },
  watch: {
    isMobile(isMobile: boolean) {
      if (isMobile) this.menuState = false
    },
  },
})
</script>

<!-- NB: This is the legacy navigation component, for businesses created before the pricing 2023 changes -->

<template>
  <v-navigation-drawer v-model="menuState" class="mini-nav business" mobile-breakpoint="md" location="left" width="300">
    <div class="d-flex flex-column pt-9 | mini-nav__flex">
      <nuxt-link :to="{ name: 'business-profile' }" class="text-no-decoration pl-5">
        <logo data-cy="indexLogo" business="light" />
      </nuxt-link>
      <v-list class="py-0 pt-10 mini-nav__top">
        <div class="pb-3">
          <v-list-item :to="{ name: 'business-members' }" prepend-icon="custom:multiple" data-cy="businessMembers">
            <v-list-item-title>
              {{ $t('business.navigation.team') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="mini-nav_submenu" exact prepend-icon=" " :to="{ name: 'business-members' }">
            <span class="mini-nav__border" />
            <v-list-item-title>
              {{ $t('business.navigation.members') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="mini-nav_submenu" prepend-icon=" " :to="{ name: 'business-members-domains' }">
            <span class="mini-nav__border" />
            <v-list-item-title>
              {{ $t('business.members.navigation.domains') }}
            </v-list-item-title>
          </v-list-item>
        </div>

        <div class="pb-3">
          <v-list-item
            prepend-icon="custom:wallet"
            :to="{
              name: hideBillingNav ? 'business-billing-payment' : 'business-billing',
            }"
          >
            <v-list-item-title>
              {{ $t('business.navigation.plan_billing') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="!hideBillingNav"
            class="mini-nav_submenu"
            prepend-icon=" "
            exact
            :to="{ name: 'business-billing' }"
          >
            <span class="mini-nav__border" />
            <v-list-item-action />

            <v-list-item-title>
              {{ $t('business.billing.navigation.billing') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="!companyHasManualInvoicingEnabled"
            class="mini-nav_submenu"
            prepend-icon=" "
            :to="{ name: 'business-billing-payment' }"
          >
            <span class="mini-nav__border" />
            <v-list-item-action />

            <v-list-item-title class="mini-nav_submenu" prepend-icon=" ">
              {{
                companyHasManualInvoicingEnabled
                  ? $t('global.payment_details')
                  : $t('business.billing.navigation.payment_method')
              }}
            </v-list-item-title>
          </v-list-item>
        </div>

        <div class="business-nav-spacing">
          <v-list-item prepend-icon="custom:lightning_big" :to="{ path: '/business/activity' }">
            <span class="mini-nav__border" />
            <v-list-item-title>
              {{ $t('business.navigation.activity') }}
            </v-list-item-title>
          </v-list-item>
        </div>

        <!-- seal -->
        <div v-if="seals.length > 1" class="business-nav-spacing">
          <v-list-item prepend-icon="custom:seal" :to="{ name: 'business-seals' }">
            <span class="mini-nav__border" />
            <v-list-item-title>
              {{ $t('business.navigation.seal') }}
            </v-list-item-title>
          </v-list-item>
        </div>

        <v-list-item prepend-icon="custom:developers" :to="{ name: 'business-developers-api' }">
          <span class="mini-nav__border" />
          <v-list-item-title class="pa-0">
            {{ $t('business.navigation.developers') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>

      <v-list class="mt-auto py-0 pt-4 | business-nav-bottom">
        <v-divider />
        <v-list-item
          prepend-icon="custom:setup_options"
          exact
          :to="{ name: 'business-settings' }"
          data-cy="businessSettingsOption"
        >
          <v-list-item-title class="pa-0">
            {{ $t('global.navigation.business_settings') }}
          </v-list-item-title>
        </v-list-item>

        <v-divider />
        <v-list-group v-if="isMobile">
          <template #activator>
            <v-list-item prepend-icon="custom:c_question" exact>
              <v-list-item-title class="pa-0">
                {{ $t('global.navigation.help_center') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <business-help-menu />
        </v-list-group>

        <v-list-item
          v-if="!isMobile"
          id="help-overview"
          prepend-icon="custom:c_question"
          exact
          class="business-nav-bottom-flyout"
        >
          <v-list-item-title class="pa-0">
            {{ $t('global.navigation.help_center') }}
          </v-list-item-title>

          <div class="caret-icon">
            <v-icon color="#B3D4F7" size="small">custom:arrow_right</v-icon>
          </div>
        </v-list-item>
        <v-menu
          v-if="!isMobile"
          :offset="[10, 105]"
          location="right"
          content-class="business-nav__overlay-menu"
          open-on-hover
          close-delay="200"
          activator="#help-overview"
          min-width="200px"
        >
          <v-card>
            <v-list nav density="compact" class="pa-1">
              <business-help-menu />
            </v-list>
          </v-card>
        </v-menu>

        <v-divider />

        <v-list-item prepend-icon="custom:go_to_skribble" exact :to="{ name: 'index' }" data-cy="mySkribbleButton">
          <v-list-item-title class="pa-0">
            {{ $t('global.navigation.back_to_skribble') }}
          </v-list-item-title>
        </v-list-item>

        <v-divider />

        <v-list-group v-if="isMobile">
          <template #activator>
            <v-list-item
              id="business-profile"
              :prepend-avatar="$t('business.navigation.business_profile_abbr')"
              class="business-profile"
              data-cy="BusinessProfile"
            >
              <v-list-item-title class="pa-0">
                {{ $t('business.navigation.business_profile') }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-truncate | mini-nav__business-profile__subtitle">
                {{ bizName }}
              </v-list-item-subtitle>
            </v-list-item>
          </template>
          <business-profile-menu />
        </v-list-group>

        <v-list-item v-if="!isMobile" id="business-profile" class="business-profile" data-cy="BusinessProfile">
          <div class="business-profile-avatar">
            <v-avatar class="mr-3 bg-primary font-weight-bold">
              {{ $t('business.navigation.business_profile_abbr') }}
            </v-avatar>
            <div class="d-flex justify-space-between align-center flex-1-1">
              <div class="d-flex flex-wrap">
                <v-list-item-title>
                  {{ $t('business.navigation.business_profile') }}
                </v-list-item-title>
                <v-list-item-subtitle class="text-truncate | mini-nav__business-profile__subtitle">
                  {{ bizName }}
                </v-list-item-subtitle>
              </div>
            </div>
            <div class="caret-icon ml-3">
              <v-icon color="#B3D4F7" size="small">custom:arrow_right</v-icon>
            </div>
          </div>
        </v-list-item>
        <v-menu
          v-if="!isMobile"
          open-on-hover
          close-delay="200"
          location="right"
          activator="#business-profile"
          :offset="[10, 38]"
          content-class="business-nav__overlay-menu"
        >
          <v-card>
            <v-list nav density="compact" class="pa-1">
              <business-profile-menu />
            </v-list>
          </v-card>
        </v-menu>
      </v-list>
    </div>
  </v-navigation-drawer>
</template>

<style lang="sass">
.mini-nav.business
  padding: 0
  &.v-navigation-drawer
    background-color: $c-mininav-bg

  .v-avatar
    font-size: 1rem
    color: $c-white

  .v-divider
    border-color: $c-grey-dark
    opacity: 1

  .v-list-item
    padding: 0 0 0 20px
    min-height: 32px
    margin: 0
    &.business-profile
      padding-left: 12px
    &:before
      +mini-nav-item-hover
      background: $c-primary
    &.is-large:not(&--active):before
      top: 9px
      width: 46px
      height: 46px
      background: $c-mininav-primary-bg
    &:hover
      background: rgba($c-primary, .12)
      color: $c-white
      &:before
        opacity: 0.1
    &__prepend
      width: 22px
      margin: 0 14px 0 0 !important
      > .v-icon
        opacity: 1
    &-title
      padding: 5px 0
      font-weight: 700
    .v-list-item__overlay
      background-color: transparent
    &--active
      color: $c-white !important
      .mini-nav__border
        &:before
          top: 3px
          bottom: 3px
          left: 0
          transform: none
          width: 8px
          opacity: 1
          border-radius: 0 4px 4px 0
          position: absolute
          color: $c-mininav-primary-bg
          background-color: currentColor
          content: ""
          +mini-nav-item-hover
      &:hover
        color: $c-white !important
        .v-list-item-title
          color: $c-white !important
    &-title
      font-size: 0.875rem
      line-height: 1.2
      +mini-nav-item-hover
    &:not(.v-list-item--active)
      color: $c-mininav-color !important
    &:hover
      color: $c-white !important
      .v-list-item-title
        color: $c-white !important
    .v-icon, svg
      color: inherit
      width: 22px
      height: 22px
    .v-ripple__container
      color: $c-primary
    &-subtitle
      color: $c-grey-dark
      font-weight: bold
  .mini-nav

    &__label
      +mini-nav-item-hover

    &__trigger
      &:before
        left: 50%
        top: 50%
        transform: translate(-50%,-50%)
        width: 46px
        height: 46px
        border-radius: 50%
      &:before
        background-color: $c-mininav-primary-bg
      span
        color: $c-mininav-color
        i
          height: 26px
          font-size: 34px
          width: 26px
      &:hover
        color: $c-white
        &:before
          opacity: 1

    &__flex
      height: auto
      min-height: 100%

    &__link
      padding-left: 20px

    &__top
      .v-list-item
        &:not(.mini-nav_submenu) .v-list-item__content
          .v-list-item-title
            text-transform: uppercase
            font-size: 0.75rem

    &__business-profile__subtitle
      color: #B3D4F7 !important
      font-weight: normal
      word-break: break-all

  &__avatar
    &:before
      display: none

  .business-nav-bottom
    .v-list-item
      padding: 20px
      min-height: 0
      +maw
        padding: 12px 0 12px 20px
      &.business-nav-bottom-flyout
        padding: 20px
        .v-icon
          +mini-nav-item-hover
        &:hover
          .v-icon
            color: white !important
            caret-color: white !important
        .v-list-item__content
          display: flex
          justify-content: space-between
        + .v-menu
          .v-list-item__content
            display: flex
      &__prepend
        margin: 0
      &__content
        padding: 0
      &__avatar
        margin: 0 12px 0 0
      &.business-profile
        +maw
          padding: 0 15px 0 0
          margin-left: -5px
        .v-icon
          +mini-nav-item-hover
        .business-profile-avatar
          display: flex
          align-items: center
        &:hover
          .v-icon
            color: white !important
            caret-color: white !important
      .v-list-item__content
        display: block // display flex makes list-item-title and list-item-subtitle in same line
        align-items: center
    .v-list-group__header
      padding: 0 20px 0 0
    .v-list-group
      padding: 10px 0 10px 20px
      min-height: 0
    .v-list-group__items .v-list-item
      padding-inline-start: 0!important
    .caret-icon > .v-icon
      width: 16px
      height: 16px

  .business-nav-spacing
    padding-bottom: 18px


.business-nav__overlay-menu

  .v-list-item
    &__content
      font-size: 0.75rem
      color: $c-grey-dark

      &:hover
        color: $c-primary !important

    .v-list-item-title
      font-weight: 700
      font-size: .875rem

.business-nav-menu
  .v-list-item-title
    font-size: 0.875rem
    font-weight: 700
    line-height: 1rem
    padding: 0 25px 0 0
</style>
