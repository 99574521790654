<script lang="ts" setup>
import DialogFreePlanDowngrade from '@/components/dialogs/business/DialogFreePlanDowngrade.vue'
import Logo from '@/components/Logo.vue'

const userStore = useUserStore()
const businessStore = useBusinessStore()
const widgetStore = useWidgetStore()

const isDowngradeDialogShown = ref(false)

const { t } = useI18n()

const upgrade = async () => {
  await navigateTo({ name: 'business-upgrade' })
}

const downgrade = async () => {
  await businessStore.deleteBusiness()
  await userStore.fetchUser()
  await navigateTo({ name: 'index' })
  businessStore.clearBusiness()
  widgetStore.setDialog('business/DialogTransactionalCsat', {})
}

const title = computed(() => {
  return businessStore.isTrialExpired
    ? t('business.navigation.locked.title')
    : t('business.navigation.locked_failed_payments.title')
})

const text = computed(() => {
  return businessStore.isTrialExpired
    ? t('business.navigation.locked.text')
    : t('business.navigation.locked_failed_payments.text')
})
</script>

<template>
  <div>
    <div class="nav-overlay">
      <div class="nav-overlay__logo">
        <logo business="light" />
      </div>
      <div class="nav-overlay__fade" />
      <div class="nav-overlay__content">
        <div class="text-h5 text-white font-weight-bold" data-cy="locked_admin_nav_title">{{ title }}</div>
        <p class="text-grey-light font-weight-regular">{{ text }}</p>
        <v-btn color="primary" block size="large" @click="upgrade">{{
          $t('business.navigation.locked.choose_plan')
        }}</v-btn>
        <v-btn color="gray" block variant="text" size="large" @click="isDowngradeDialogShown = true">
          {{ $t('business.navigation.locked.change_to_free') }}
        </v-btn>
      </div>
      <div class="nav-overlay__logout">
        <v-divider />
        <v-list>
          <v-list-item prepend-icon="custom:leave" :to="{ name: 'logout' }">
            <v-list-item-title>{{ $t('global.navigation.logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </div>
    </div>
    <dialog-free-plan-downgrade v-model="isDowngradeDialogShown" @accept="downgrade" />
  </div>
</template>

<style lang="sass" scoped>
$content-padding: 5 * $bw

.nav-overlay
  display: flex
  flex-direction: column
  position: absolute
  z-index: 1
  top: 0
  right: 0
  bottom: 0
  left: 0
  backdrop-filter: blur(8px)
  pointer-events: all

  &__logo
    padding: 9 * $bw $content-padding
    flex: 1

  &__fade
    width: 100%
    height: 10vh
    background-image: linear-gradient(to top, $c-mininav-bg, transparent)

  &__content
    background-color: $c-mininav-bg
    padding: $content-padding 8 * $bw
    > * + *
      margin-top: 4 * $bw

    p
      font-size: 0.875rem

  &__logout
    height: 20 * $bw
    background-color: $c-mininav-bg
    flex-grow: 0

    .v-list-item
      padding: 20px 0 20px 20px
      min-height: 0
      +maw
        padding: 12px 0 12px 20px
      &.business-nav-bottom-flyout
        padding: 20px
        .v-icon__component
          +mini-nav-item-hover
        &:hover
          .v-icon__component
            color: white !important
            caret-color: white !important
      &__action
        margin: 0
      &__content
        padding: 0
      &__avatar
        margin: 0 12px 0 0
      &.business-profile
        padding: 24px 20px 24px 12px
        +maw
          padding: 16px 20px 16px 12px
        .v-icon__component
          +mini-nav-item-hover
        &:hover
          .v-icon__component
            color: white !important
            caret-color: white !important
</style>
