<template>
  <v-list-item v-for="(item, i) in navigation" :key="`${item}-${i}`" :to="{ name: item.routeName }" exact>
    <template #prepend>
      <v-icon>{{ item.icon }}</v-icon>
    </template>

    <v-list-item-title>
      {{ item.displayTitle }}
    </v-list-item-title>
  </v-list-item>
</template>

<script lang="ts">
export default defineComponent({
  name: 'BusinessProfileMenu',
  data() {
    return {
      navigation: [
        {
          routeName: 'business-profile',
          displayTitle: this.$t('business.navigation.business_profile'),
          icon: 'custom:business_profile',
          cypressIdentifier: 'profileButton',
        },
        {
          routeName: 'business-profile-aes-for-business',
          displayTitle: this.$t('business.profile.navigation.aes'),
          icon: 'custom:business_aes',
          cypressIdentifier: 'AESForBusinessButton',
        },
        {
          routeName: 'logout',
          displayTitle: this.$t('global.navigation.logout'),
          icon: 'custom:leave',
          cypressIdentifier: 'logoutButton',
        },
      ],
    }
  },
})
</script>

<style></style>
