<template>
  <v-card
    class="px-6 mx-auto | trial-expiration"
    data-cy="trialWidget"
    :max-width="fullWidth ? '100%' : 283"
    :elevation="fullWidth ? 0 : 3"
  >
    <div />
    <div
      v-if="!fullWidth"
      :class="`trial-expiration__indicator
        trial-expiration__indicator--${indicatorColor}`"
    />
    <div class="text-center mb-0 pb-1 pb-lg-6 pt-lg-10 | trial-expiration__title">
      <i18n-t
        v-if="daysTrialEnds > 1"
        keypath="widget.trial.text.expiration.multiple_days"
        tag="strong"
        class="d-block"
      >
        <template #highlight>
          <span :class="`trial-expiration__title--${indicatorColor}`">
            {{ $t('widget.trial.expiration.days', { count: daysTrialEnds }) }}
          </span>
        </template>
      </i18n-t>
      <i18n-t
        v-else-if="daysTrialEnds === 1"
        keypath="widget.trial.text.expiration.one_day"
        tag="strong"
        class="d-block"
      >
        <template #highlight>
          <span class="trial-expiration__title--red">
            {{ $t('widget.trial.expiration.days', { count: 1 }) }}
          </span>
        </template>
      </i18n-t>
      <i18n-t v-else keypath="widget.trial.text.expiration.less_than_day" tag="strong" class="d-block">
        <template #highlight>
          <span class="trial-expiration__title--red">
            {{ $t('widget.trial.expiration.days', { count: 0 }) }}
          </span>
        </template>
      </i18n-t>
    </div>
    <v-card-text>
      <v-row>
        <v-col>
          <p
            class="text-center pb-3 pb-lg-8 mb-0 whitespace-normal | trial-expiration__text"
            :class="{ 'font-weight-bold': !fullWidth }"
          >
            {{ $t('widget.trial.expiration.text_upgrade') }}
          </p>
          <v-btn
            class="flex-grow-1 ma-0 mb-10 | trial-expiration__button"
            size="x-large"
            block
            color="info"
            variant="outlined"
            @click.stop="trackAndNavigate()"
          >
            {{ $t('global.upgrade_now') }}
          </v-btn>
          <hr v-if="!fullWidth" class="trial-expiration__divider" />
          <p
            class="text-center pt-md-4 mb-4"
            :class="{
              'px-10': !fullWidth,
            }"
          >
            <small class="justify-center | trial-expiration__note">
              <i18n-t keypath="widget.trial.expiration.help.text">
                <template #link>
                  <a :href="$t('widget.trial.expiration.help.link_url')">{{
                    $t('widget.trial.expiration.help.link_text')
                  }}</a>
                </template>
              </i18n-t>
            </small>
          </p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import differenceInDays from 'date-fns/differenceInDays'
import type { PropType } from 'vue'

export default defineComponent({
  name: 'WidgetTrialExpiration',
  props: {
    trialEndDate: {
      type: Date as PropType<Date>,
      required: true,
    },
    fullWidth: Boolean,
  },
  computed: {
    expiresInHelper(): string {
      if (this.daysTrialEnds > 1) return 'multipleDays'
      if (this.daysTrialEnds === 1) return 'oneDay'
      return 'lessOneDay'
    },
    urgency(): string {
      if (this.daysTrialEnds <= 7) return 'urgent'
      return 'regular'
    },
    indicatorColor(): string {
      if (this.urgency === 'regular') return 'green'
      return 'red'
    },
    daysTrialEnds(): number {
      return differenceInDays(this.trialEndDate, new Date())
    },
    dynamicTranslations(): TranslationKeysObject {
      return {
        oneDay: this.$t('widget.trial.expiration.title_one_day', {
          number: this.daysTrialEnds,
          class: 'trial-expiration__title--red',
        }),
        lessOneDay: this.$t('widget.trial.expiration.title_less_one_day', {
          class: 'trial-expiration__title--red',
        }),
        multipleDays: this.$t('widget.trial.expiration.title_multiple_days', {
          number: this.daysTrialEnds,
          class: `trial-expiration__title--${this.indicatorColor}`,
        }),
      }
    },
  },
  methods: {
    trackAndNavigate(): void {
      return navigateTo({ name: 'business-upgrade' })
    },
  },
})
</script>

<style lang="sass">
$ns: trial-expiration

.#{$ns}
  // override vuetify v-card text and title behaviour
  .v-card-title, .v-card-text
    padding: 0
    margin: 0

  &__title
    word-break: normal
    font-size: 1.75rem
    line-height: 2rem

    &--red
      color: $c-danger
    &--green
      color: $c-success

  &__text
    color: $c-grey-dark
    font-size: 0.875rem
    line-height: 20px

  &__indicator
    height: 8px
    border-radius: 0 0 3px 3px
    &--red
      background-color: $c-danger
    &--green
      background-color: $c-success

  &__button
    white-space: normal

  &__divider
    opacity: 0.5
    border: 1px solid $c-grey-light

  &__note
    font-size: 12px
    line-height: 18px
    color: $c-grey-dark

    strong
      color: $c-grey-dark

  &__help
    font-size: 12px
    line-height: 18px
    color: $c-grey-dark
</style>
