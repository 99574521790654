<script setup lang="ts">
const { t } = useI18n()

const businessStore = useBusinessStore()
const systemStore = useSystemStore()

const supportContact = computed(() => businessStore.settings.supportContact)

const contactLink = computed(() => {
  // if business has internal level support - return always eng contact form
  if (supportContact.value?.email) {
    return t('global.navigation.contact.link_always_en')
  } else {
    return systemStore.platform === 'EU'
      ? t('global.navigation.contact.link_form_de')
      : t('global.navigation.contact.link')
  }
})

const callSupportLink = computed(() => {
  if (businessStore.settings.supportContact?.adminPhone) {
    return systemStore.platform === 'EU'
      ? t('global.navigation.call.call_support_form_de')
      : t('global.navigation.call.call_support_form')
  }

  return ''
})
</script>

<template>
  <v-list-item :href="$t('global.navigation.admin_guide.link')" target="_blank" rel="noopener">
    <template #prepend>
      <v-icon>custom:admin_guide</v-icon>
    </template>

    <v-list-item-title>
      {{ $t('global.navigation.admin_guide.label') }}
    </v-list-item-title>
  </v-list-item>
  <v-list-item :href="$t('global.navigation.helpcenter.link')" target="_blank" rel="noopener">
    <template #prepend>
      <v-icon>custom:help_center</v-icon>
    </template>

    <v-list-item-title>
      {{ $t('global.navigation.helpcenter.label') }}
    </v-list-item-title>
  </v-list-item>
  <v-list-item :href="$t('global.navigation.tutorials.link')" target="_blank" rel="noopener">
    <template #prepend>
      <v-icon>custom:video_tutorials</v-icon>
    </template>

    <v-list-item-title>
      {{ $t('global.navigation.tutorials.label') }}
    </v-list-item-title>
  </v-list-item>
  <v-list-item :href="contactLink" target="_blank" rel="noopener">
    <template #prepend>
      <v-icon>custom:contact_email</v-icon>
    </template>

    <v-list-item-title>
      {{ $t('global.navigation.contact.label') }}
    </v-list-item-title>
  </v-list-item>
  <v-list-item v-if="supportContact.adminPhone" :href="callSupportLink" target="_blank" rel="noopener">
    <template #prepend>
      <v-icon>custom:support_hotline</v-icon>
    </template>
    <v-list-item-title> {{ $t('global.navigation.call.label') }} </v-list-item-title>
    <v-list-item-subtitle class="mb-2">
      {{ supportContact.adminPhone }}
    </v-list-item-subtitle>
  </v-list-item>
</template>
